<template>
    <v-card v-if="editView" flat>
        <v-card-text>
            <v-list dense>
                <template>
                    <v-subheader v-text="$t('views.sections.general')" />
                    <v-list-item>
                        <v-list-item-content :class="classTitle" v-text="$t('views.attributes.group')" />
                        <v-list-item-content class="py-0 align-end">
                            <v-text-field
                                v-model="editView.group"
                                :counter="20"
                                :label="$t('views.attributes.group')"
                                required
                                color="red"
                            />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content :class="classTitle" v-text="$t('views.attributes.name')" />
                        <v-list-item-content class="py-0 align-end">
                            <v-text-field v-model="editView.name" :counter="20" required color="red" />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content :class="classTitle" v-text="$t('views.attributes.label')" />
                        <v-list-item-content class="py-0 align-end">
                            <v-text-field v-model="editView.label" :counter="20" required color="red" />
                        </v-list-item-content>
                    </v-list-item>
                    <v-subheader v-text="$t('views.sections.filters')" />
                    <v-list-item>
                        <v-list-item-content :class="classTitle" v-text="$t('views.attributes.filter')" />
                        <v-list-item-content class="py-0 align-end">
                            <viewFilter v-model="editView.filters" />
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-subheader v-text="$t('views.sections.cols')" />
                <v-list-item>
                    <v-list-item-content :class="classTitle" v-text="$t('views.attributes.cols')" />
                    <v-list-item-content class="py-0 align-end">
                        <colSelector v-model="editView.cols" />
                    </v-list-item-content>
                </v-list-item>
                <v-subheader v-text="$t('views.sections.group')" />
                <v-list-item>
                    <v-list-item-content :class="classTitle" v-text="$t('views.attributes.order')" />
                    <v-list-item-content class="py-0 align-end">
                        <orderSelector v-model="editView.defaultOrder" />
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content :class="classTitle" v-text="$t('views.attributes.groupCol')" />
                    <v-list-item-content class="py-0 align-end">
                        <groupSelector v-model="editView.groupCol" />
                    </v-list-item-content>
                </v-list-item>
                <template v-if="editView.system === false">
                    <v-subheader v-text="$t('views.sections.security')" />
                    <v-list-item>
                        <v-list-item-content :class="classTitle" v-text="$t('views.attributes.securityRoles')" />
                        <v-list-item-content class="py-0 align-end">
                            <roles-selector label="" v-model="editView.security.roles" />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content :class="classTitle" v-text="$t('views.attributes.securityUsers')" />
                        <v-list-item-content class="py-0 align-end">
                            <users-selector label="" v-model="editView.security.users" />
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn v-if="editView.system === false" text @click="cancel" v-text="$t('buttons.cancel')" />
            <v-btn v-if="allowDelete" dark color="red" @click="deleteView" v-text="$t('buttons.delete')" />
            <v-btn color="success" @click="updateView" v-text="$t('buttons.refresh')" />
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import viewFilter from '@/components/views/filter';
import rolesSelector from '@/components/selectors/multiple/roles';
import usersSelector from '@/components/selectors/multiple/users';
import colSelector from '@/components/views/colSelector';
import orderSelector from '@/components/views/orderSelector';
import groupSelector from '@/components/views/groupSelector';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'view-edit',
    data: () => ({
        editView: undefined,
    }),
    props: {},
    components: {
        colSelector,
        orderSelector,
        groupSelector,
        viewFilter,
        rolesSelector,
        usersSelector,
    },
    mounted() {
        this.editView = Clone(this.view);
    },
    props: {
        view: {
            required: true,
        },
        allowDelete: {
            default: true,
        },
    },
    watch: {
        view: function (value) {
            this.editView = Clone(value);
        },
    },
    computed: {
        classTitle() {
            const clazz = 'font-weight-medium blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-3' : clazz + 'text--darken-3';
        },
    },
    methods: {
        updateView() {
            this.$emit('update-view', this.editView);
        },
        deleteView() {
            this.$emit('delete-view', this.editView);
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>
